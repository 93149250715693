import React from 'react'
import { Box, Button, Container, Typography } from '@mui/material'

const FeatureCard = ({ tagline, title, description, imageUrl, altText, onContact }) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: { xs: 'column', md: 'row' },
      overflow: 'hidden',
      width: '100%',
      maxWidth: '792px',
      margin: '0 auto',
      padding: { xs: '24px', md: '32px' },
      backgroundColor: 'inherit',
    }}
  >
    <Box
      sx={{
        padding: { xs: '48px', md: '64px' },
        flex: '0 0 50%',
        backgroundColor: '#fffff5',
        borderRadius: { xs: '10px 10px 0 0', md: '10px 0 0 10px' },
      }}
    >
      <Typography
        sx={{
          color: '#f79009',
          fontSize: '16px',
          fontFamily: '"Plus Jakarta Sans", sans-serif',
          fontWeight: 600,
          marginBottom: '12px',
        }}
      >
        {tagline}
      </Typography>
      <Typography
        variant="h5"
        sx={{
          fontSize: { xs: '24px', md: '30px' },
          fontFamily: '"Plus Jakarta Sans", sans-serif',
          fontWeight: 700,
          color: '#000',
          marginBottom: '16px',
        }}
      >
        {title}
      </Typography>
      <Typography
        sx={{
          fontSize: { xs: '16px', md: '17px' },
          fontFamily: '"Inter", sans-serif',
          fontWeight: 400,
          color: '#000',
          marginBottom: '32px',
          maxWidth: '500px',
        }}
      >
        {description}
      </Typography>
      <Button
        onClick={onContact}
        sx={{
          backgroundColor: 'transparent',
          color: '#000',
          fontSize: '16px',
          textTransform: 'none',
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
          padding: 0,
          '&:hover': {
            backgroundColor: 'transparent',
          },
          cursor: 'pointer',
        }}
      >
        Contáctanos
        <Box
          component="img"
          src="https://mangoticket.nyc3.cdn.digitaloceanspaces.com/landing/features1.svg"
          alt="Arrow icon"
          sx={{
            width: '24px',
            height: '24px',
          }}
        />
      </Button>
    </Box>
    <Box
      sx={{
        flex: '0 0 50%',
        backgroundColor: 'inherit',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: { xs: '0 0 10px 10px', md: '0 10px 10px 0' },
        minHeight: { xs: '250px', md: '350px' },
        maxHeight: { xs: '300px', md: '400px' },
        overflow: 'hidden',
      }}
    >
      <Box
        component="img"
        src={imageUrl}
        alt={altText}
        sx={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          padding: 0,
        }}
      />
    </Box>
  </Box>
)

const Features = ({ onContact }) => {
  const featuresData = [
    {
      tagline: 'Eficiencia',
      title: 'Todo en uno para tus eventos',
      description: 'Integra productos de barra fácilmente en tus eventos.',
      imageUrl:
        'https://mangoticket.nyc3.cdn.digitaloceanspaces.com/landing/features2.png',
      altText: 'All-in-one event management',
    }
  ]

  return (
    <Box
      component="section"
      sx={{
        padding: { xs: '60px 20px 120px', md: '90px 64px 150px' },
        backgroundColor: 'inherit',
      }}
    >
      <Container maxWidth="lg">
        <Box
          sx={{
            textAlign: 'center',
            maxWidth: '768px',
            mx: 'auto',
            mb: { xs: 6, md: 10 },
          }}
        >
          <Typography
            sx={{
              color: '#f79009',
              fontSize: '16px',
              fontFamily: '"Plus Jakarta Sans", sans-serif',
              fontWeight: 600,
              marginBottom: '16px',
            }}
          >
            Simplicidad
          </Typography>
          <Typography
            variant="h2"
            sx={{
              fontSize: { xs: '40px', md: '48px' },
              fontFamily: '"Plus Jakarta Sans", sans-serif',
              fontWeight: 700,
              lineHeight: 1.2,
              marginBottom: '24px',
            }}
          >
            Herramientas para eventos,{' '}
            <Box component="span" sx={{ color: '#fff' }}>
              sin complicaciones.
            </Box>
          </Typography>
          <Typography
            sx={{
              fontSize: '18px',
              fontFamily: '"Inter", sans-serif',
              fontWeight: 400,
              color: '#aaa',
            }}
          >
            Gestión de eventos sin complicaciones para todos.
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          <FeatureCard {...featuresData[0]} onContact={onContact} />
        </Box>
      </Container>
    </Box>
  )
}

export default Features
