import React, { useRef } from 'react'
import { Box, Button, Container, Typography } from '@mui/material'

const Hero = () => {
  const benefitsRef = useRef(null)

  const scrollToBenefits = () => {
    benefitsRef.current?.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <>
      <Box
        component="section"
        sx={{
          marginTop: { xs: '64px', md: '100px' },
          padding: { xs: '32px 16px', md: '80px 64px' },
          textAlign: 'center',
        }}
      >
        <Container maxWidth="lg">
          <Box sx={{ maxWidth: { xs: '100%', md: '900px' }, mx: 'auto', px: { xs: 2, md: 0 }, mt: { xs: 2, md: 0 } }}>
            <Typography
              variant="h1"
              sx={{
                fontSize: { xs: '32px', sm: '40px', md: '64px', lg: '72px' },
                lineHeight: { xs: 1.3, md: 1.1 },
                fontWeight: 800,
                fontFamily: '"Plus Jakarta Sans", sans-serif',
                mb: { xs: 4, md: 4 },
                letterSpacing: { xs: '-0.01em', md: '-0.02em' },
                maxWidth: { xs: '100%', md: '1000px' },
                mx: 'auto',
                px: { xs: 2, md: 0 },
              }}
            >
              Saca el máximo potencial
              <Box component="span" sx={{ display: 'block', mt: { xs: 1, md: 2 } }}>
                a tus eventos.
              </Box>
            </Typography>
            <Typography
              variant="h2"
              sx={{
                fontSize: { xs: '20px', sm: '28px', md: '40px', lg: '48px' },
                lineHeight: { xs: 1.3, md: 1.2 },
                fontWeight: 700,
                fontFamily: '"Plus Jakarta Sans", sans-serif',
                mb: { xs: 3, md: 4 },
                color: '#fff',
                maxWidth: { xs: '100%', md: '800px' },
                mx: 'auto',
              }}
            >
              Mejora la experiencia de tus asistentes.
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontSize: { xs: '14px', sm: '16px', md: '18px' },
                lineHeight: 1.5,
                fontFamily: '"Inter", sans-serif',
                fontWeight: 400,
                color: '#eee',
                mb: { xs: 4, md: 5 },
                maxWidth: '700px',
                mx: 'auto',
                px: { xs: 2, md: 0 },
                opacity: 0.9,
              }}
            >
              Gestiona entradas y productos de barra en un solo lugar, ofreciendo a tus asistentes una experiencia sin
              interrupciones.
            </Typography>
            <Box
              sx={{
                display: 'flex',
                gap: { xs: 1, md: 2 },
                justifyContent: 'center',
                flexWrap: 'wrap',
                mb: { xs: 4, md: 8 },
              }}
            >
              <Button
                variant="contained"
                href="/events/new"
                sx={{
                  backgroundColor: '#f79009',
                  color: '#fff',
                  borderRadius: '10px',
                  px: { xs: 2, md: 3 },
                  py: 1.5,
                  fontSize: { xs: '14px', md: '16px' },
                  textTransform: 'none',
                  width: { xs: '140px', md: 'auto' },
                  '&:hover': {
                    backgroundColor: '#e67e00',
                  },
                }}
              >
                Crea tu evento
              </Button>
              <Button
                variant="outlined"
                onClick={scrollToBenefits}
                sx={{
                  color: '#fff',
                  borderColor: '#f79009',
                  borderRadius: '10px',
                  px: { xs: 2, md: 3 },
                  py: 1.5,
                  fontSize: { xs: '14px', md: '16px' },
                  textTransform: 'none',
                  width: { xs: '140px', md: 'auto' },
                  '&:hover': {
                    borderColor: '#e67e00',
                  },
                }}
              >
                Descubre
              </Button>
            </Box>
          </Box>
          <Box
            component="img"
            src="https://mangoticket.nyc3.cdn.digitaloceanspaces.com/landing/hero.png"
            alt="MangoTicket Event Management"
            sx={{
              maxWidth: '100%',
              height: 'auto',
              mt: { xs: 2, md: 4 },
              px: { xs: 1, md: 0 },
              mb: { xs: 0, md: 4 },
            }}
          />
        </Container>
      </Box>
      <Box ref={benefitsRef} />
    </>
  )
}

export default Hero
