import React, { useState, useEffect } from 'react'
import { Box, Container, Typography, Link, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import MangoLogoWhite from '../../../assets/images/MangoTicket-Color-White.svg'

const PDFModal = ({ open, onClose, url, title }) => {
  useEffect(() => {
    if (open) {
      document.body.classList.add('modal-open')
    } else {
      document.body.classList.remove('modal-open')
    }

    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [open])

  useEffect(() => {
    const styleElement = document.createElement('style')
    styleElement.innerHTML = `
      .modal-open {
        overflow: hidden !important;
        height: 100vh;
        position: fixed;
        width: 100%;
      }
    `
    document.head.appendChild(styleElement)

    return () => {
      document.head.removeChild(styleElement)
    }
  }, [])

  if (!open) return null

  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        bgcolor: 'rgba(0, 0, 0, 0.8)',
        zIndex: 1300,
      }}
      onClick={onClose}
    >
      <Box
        sx={{
          position: 'relative',
          width: '90vw',
          height: '90vh',
          bgcolor: 'background.paper',
          borderRadius: 2,
          boxShadow: 24,
          p: 0,
          overflow: 'hidden',
          '& iframe': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            border: 'none',
          }
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            zIndex: 1,
            color: '#f79009',
            '&:hover': {
              backgroundColor: 'rgba(247, 144, 9, 0.1)',
            },
          }}
        >
          <CloseIcon />
        </IconButton>
        <iframe
          src={`${url}#toolbar=0&navpanes=0&scrollbar=0`}
          title={title}
          width="100%"
          height="100%"
          style={{ 
            border: 'none',
            display: 'block'
          }}
          frameBorder="0"
        />
      </Box>
    </Box>
  )
}

const StoreButton = ({ src, alt, href }) => (
  <Link 
    href={href}
    target="_blank"
    rel="noopener noreferrer"
    sx={{
      display: 'block',
      transition: 'transform 0.2s ease',
      '&:hover': {
        transform: 'scale(1.05)',
      }
    }}
  >
    <Box
      component="img"
      src={src}
      alt={alt}
      sx={{
        height: { xs: '32px', md: '40px' },
        width: 'auto',
      }}
    />
  </Link>
)

const LandingFooter = () => {
  const [termsOpen, setTermsOpen] = useState(false)
  const [privacyOpen, setPrivacyOpen] = useState(false)

  const handleTermsOpen = () => setTermsOpen(true)
  const handleTermsClose = () => setTermsOpen(false)
  const handlePrivacyOpen = () => setPrivacyOpen(true)
  const handlePrivacyClose = () => setPrivacyOpen(false)

  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: '#04121a',
        padding: { xs: '24px 20px', md: '32px 64px' },
        borderTop: '1px solid rgba(255, 255, 255, 0.1)',
      }}
    >
      <Container 
        maxWidth="lg"
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            maxWidth: '1200px',
            '& > *:not(:last-child)': {
              marginBottom: { xs: '32px', md: 0 },
            },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: { xs: 'center', md: 'flex-start' },
              flex: { md: '1 1 0' },
              maxWidth: { md: '300px' },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                justifyContent: 'center',
                pt: { md: '16px' },
              }}
            >
              <Box
                component="img"
                src={MangoLogoWhite}
                alt="MangoTicket"
                sx={{
                  height: { xs: '32px', md: '40px' },
                  width: 'auto',
                  marginBottom: '16px',
                  display: 'block',
                  marginLeft: { md: 'auto' },
                  marginRight: { md: 'auto' },
                }}
              />
              <Typography
                sx={{
                  fontSize: '14px',
                  fontFamily: '"Inter", sans-serif',
                  fontWeight: 400,
                  color: '#aaa',
                  textAlign: { xs: 'center', md: 'center' },
                  width: '100%',
                }}
              >
                © 2023 MangoTicket. Todos los derechos reservados.
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              display: 'flex',
              gap: { xs: '12px', md: '16px' },
              alignItems: 'center',
              flex: { md: '2 1 0' },
              justifyContent: 'center',
            }}
          >
            <StoreButton
              src="https://mangoticket.nyc3.cdn.digitaloceanspaces.com/landing/googlePlayBadge.svg"
              alt="Get it on Google Play"
              href="#"
            />
            <StoreButton
              src="https://mangoticket.nyc3.cdn.digitaloceanspaces.com/landing/appStoreBadge.svg"
              alt="Download on the App Store"
              href="#"
            />
          </Box>

          <Box
            sx={{
              display: 'flex',
              gap: '32px',
              alignItems: 'center',
              flex: { md: '1 1 0' },
              maxWidth: { md: '300px' },
              justifyContent: { md: 'flex-end' },
            }}
          >
            <Link
              component="button"
              onClick={handleTermsOpen}
              sx={{
                color: '#fff',
                textDecoration: 'none',
                background: 'none',
                border: 'none',
                cursor: 'pointer',
                fontSize: '14px',
                '&:hover': {
                  color: '#f79009',
                },
              }}
            >
              Términos
            </Link>
            <Link
              component="button"
              onClick={handlePrivacyOpen}
              sx={{
                color: '#fff',
                textDecoration: 'none',
                background: 'none',
                border: 'none',
                cursor: 'pointer',
                fontSize: '14px',
                '&:hover': {
                  color: '#f79009',
                },
              }}
            >
              Privacidad
            </Link>
          </Box>
        </Box>
      </Container>

      <PDFModal
        open={termsOpen}
        onClose={handleTermsClose}
        url="https://mangoticket-legal.nyc3.cdn.digitaloceanspaces.com/TERMINOS%20Y%20CONDICIONES%20MT.pdf"
        title="Términos y Condiciones"
      />

      <PDFModal
        open={privacyOpen}
        onClose={handlePrivacyClose}
        url="https://mangoticket-legal.nyc3.cdn.digitaloceanspaces.com/Poli%CC%81tica%20de%20Privacidad%20MT.pdf"
        title="Política de Privacidad"
      />
    </Box>
  )
}

export default LandingFooter
