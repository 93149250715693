import React, { useState } from 'react'
import { AppBar, Box, Button, Toolbar, IconButton, Drawer, List, ListItem } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import MenuIcon from '@mui/icons-material/Menu'

const LandingHeader = ({ scrollToFAQ, onContact }) => {
  const isMobile = useMediaQuery('(max-width:991px)')
  const [mobileOpen, setMobileOpen] = useState(false)

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const mobileMenu = (
    <Box sx={{ 
      width: 250, 
      bgcolor: '#04121a', 
      height: '100%', 
      py: 2 
    }}>
      <List>
        <ListItem>
          <Button 
            href="/dashboard" 
            fullWidth
            sx={{ 
              color: '#fff', 
              fontSize: '16px', 
              textTransform: 'none', 
              py: 1 
            }}
          >
            Productores
          </Button>
        </ListItem>
        <ListItem>
          <Button 
            onClick={() => {
              scrollToFAQ()
              handleDrawerToggle()
            }}
            fullWidth
            sx={{ color: '#fff', fontSize: '16px', textTransform: 'none', py: 1 }}
          >
            Preguntas Frecuentes
          </Button>
        </ListItem>
        <ListItem>
          <Button 
            onClick={onContact}
            fullWidth
            sx={{ color: '#fff', fontSize: '16px', textTransform: 'none', py: 1 }}
          >
            Contáctanos
          </Button>
        </ListItem>
        <ListItem>
          <Button
            variant="contained"
            href="/events/new"
            fullWidth
            sx={{
              backgroundColor: '#f79009',
              color: '#fff',
              borderRadius: '10px',
              py: 1,
              fontSize: '16px',
              textTransform: 'none',
              '&:hover': {
                backgroundColor: '#e67e00',
              },
            }}
          >
            Crea tu Evento
          </Button>
        </ListItem>
      </List>
    </Box>
  )

  return (
    <Box
      component="header"
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        zIndex: 1100,
        backgroundColor: '#04121a',
        display: 'flex',
        justifyContent: 'center',
        padding: { 
          xs: '12px 0', 
          md: '16px 0' 
        },
      }}
    >
      <Box
        sx={{
          maxWidth: '1200px',
          width: '100%',
          px: { 
            xs: '16px',
            md: '64px'
          },
        }}
      >
        <Toolbar
          disableGutters
          sx={{
            width: '100%',
            px: { xs: 2, md: 3 },
            display: 'flex',
            alignItems: 'center',
            backgroundColor: 'white',
            minHeight: { xs: '52px', sm: '56px' },
            borderRadius: '10px',
          }}
        >
          <Box
            component="img"
            src="https://mangoticket.nyc3.cdn.digitaloceanspaces.com/landing/landingHeader.svg"
            alt="MangoTicket Logo"
            sx={{
              width: { xs: '100px', md: '148px' },
              height: 'auto',
              flexShrink: 0,
            }}
          />
          <Box sx={{ flexGrow: 1 }} />
          
          {isMobile ? (
            <>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ color: '#000' }}
              >
                <MenuIcon />
              </IconButton>
              <Drawer
                anchor="right"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                  keepMounted: true, // Better mobile performance
                }}
              >
                {mobileMenu}
              </Drawer>
            </>
          ) : (
            <>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: { xs: 1, md: 4 },
                  mr: 5,
                }}
              >
                <Button href="/dashboard" sx={{ color: '#000', fontSize: '16px', textTransform: 'none' }}>
                  Productores
                </Button>
                <Button onClick={scrollToFAQ} sx={{ color: '#000', fontSize: '16px', textTransform: 'none' }}>
                  Preguntas Frecuentes
                </Button>
              </Box>
              <Button
                variant="contained"
                href="/events/new"
                sx={{
                  backgroundColor: '#f79009',
                  color: '#fff',
                  borderRadius: '10px',
                  padding: '8px 20px',
                  fontSize: '16px',
                  textTransform: 'none',
                  '&:hover': {
                    backgroundColor: '#e67e00',
                  },
                }}
              >
                Crea tu Evento
              </Button>
            </>
          )}
        </Toolbar>
      </Box>
    </Box>
  )
}

export default LandingHeader
